import {Box, Paper, Typography} from "@mui/material";
import React from "react";
import EuroIcon from '@mui/icons-material/Euro';
import {PageParagraph} from "../../Utils/PageParagraph";

export const Enroll = () => {
    return (
        <Box maxWidth={"md"} mb={8} sx={{display: "flex", flexDirection: "column"}}>
            <PageParagraph text={`Coût de la Formation`}/>
            <Paper variant={"outlined"} sx={{mt: 1, overflow: "hidden"}}>
                <Box sx={{display: "flex", flexDirection: "column"}}>
                    <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}}}>
                        <Box sx={{display: "flex", flexDirection: "column", width: {xs: "100%", md: "33.4%"}}}>
                            <Box display={"flex"} width={"100%"} height={42} fontSize={24} bgcolor={"#DDD"} color={"darkgoldenrod"}>
                                <Box m={"auto"}>
                                    <EuroIcon fontSize={"inherit"}/>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={"100%"} height={100} bgcolor={"primary.dark"} color={"primary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"h6"}>
                                        Souscription à toutes les sessions
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", width: {xs: "100%", md: "33.4%"}}}>
                            <Box display={"flex"} width={"100%"} height={42} bgcolor={"primary.main"} color={"primary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"body2"} fontWeight={500}>
                                        Membres d’Eglises
                                        fondatrices
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={"100%"} height={100} bgcolor={"primary.light"} color={"primary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"h6"}>
                                        200€/session*
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box display={"flex"} sx={{display: "flex", flexDirection: "column", width: {xs: "100%", md: "33.4%"}}}>
                            <Box display={"flex"} width={"100%"} height={42} bgcolor={"secondary.dark"} color={"secondary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"body2"} fontWeight={500}>
                                        Autres personnes
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={"100%"} height={100} bgcolor={"secondary.light"} color={"secondary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"h6"}>
                                        250€/session*
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>

                    <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}}}>
                        <Box sx={{display: "flex", flexDirection: "column", width: {xs: "100%", md: "33.4%"}}}>
                            <Box sx={{display: {xs: "flex", md: "none"}}} width={"100%"} height={42} fontSize={24} bgcolor={"#DDD"} color={"darkgoldenrod"}>
                                <Box m={"auto"}>
                                    <EuroIcon fontSize={"inherit"}/>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={"100%"} height={100} bgcolor={"primary.dark"} color={"primary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"h6"}>
                                        Souscription session au choix
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", width: {xs: "100%", md: "33.4%"}}}>
                            <Box sx={{display: {xs: "flex", md: "none"}}} width={"100%"} height={42} bgcolor={"primary.main"} color={"primary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"body2"} fontWeight={500}>
                                        Membres d’Eglises
                                        fondatrices
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={"100%"} height={100} bgcolor={"primary.light"} color={"primary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"h6"}>
                                        250€/session*
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", width: {xs: "100%", md: "33.4%"}}}>
                            <Box sx={{display: {xs: "flex", md: "none"}}} width={"100%"} height={42} bgcolor={"secondary.dark"} color={"secondary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"body2"} fontWeight={500}>
                                        Autres personnes
                                    </Typography>
                                </Box>
                            </Box>
                            <Box display={"flex"} width={"100%"} height={100} bgcolor={"secondary.light"} color={"secondary.contrastText"}>
                                <Box p={1} m={"auto"}>
                                    <Typography variant={"h6"}>
                                        300€/session*
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
            <Box ml={"auto"}>
                <Typography variant={"body2"} color={"text.secondary"}>* 4 sessions par an</Typography>
            </Box>
        </Box>
    );
};