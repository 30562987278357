import {Box, Container, Typography} from "@mui/material";
import {FooterLink} from "./components/FooterLink";
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import * as React from "react";
import EmailIcon from "@mui/icons-material/Email";

export const Footer = () => {
    const year = new Date().getFullYear();
    return (
        <Box mt={"auto"} mb={0}
             sx={{
                 display: "flex",
                 flexDirection: "column",
                 bgcolor: "primary.main",
                 color: "primary.light",
                 zIndex: 10,
             }}>
            <Container>
                <Box mt={2} ml={"auto"} mr={"auto"}
                     sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, justifyContent: "space-between"}}>
                    <Box mt={2} width={"18rem"} sx={{display: "flex", flexDirection: "column"}}>
                        <Box mr={"auto"}>
                            <Typography variant={"h6"} textTransform={"uppercase"}>ITEAG</Typography>
                            <Box mb={1} borderTop={"1px solid"} width={120}/>
                            <Typography variant={"subtitle1"}>201 lot Pointe d'Or</Typography>
                            <Typography variant={"subtitle1"}>97139 Abymes (Les)</Typography>
                            <Typography variant={"subtitle1"}>Guadeloupe</Typography>
                            <Box display={"flex"}>
                                <Box fontSize={"1.4rem"} color={"primary.light"} mr={1}>
                                    <LocalPhoneIcon fontSize={"inherit"}/>
                                </Box>
                                <a href={"tel:+590 690 37 64 17"} style={{textDecoration: "none"}}>
                                    <Typography variant={"subtitle1"} color={"primary.light"}>
                                        +590 690 37 64 17
                                    </Typography>
                                </a>
                            </Box>
                            <Box display={"flex"}>
                                <Box fontSize={"1.4rem"} color={"primary.light"} mr={1}>
                                    <EmailIcon fontSize={"inherit"}/>
                                </Box>

                                <a href={"mailto:secretariat@iteag.org"} style={{textDecoration: "none"}}>
                                    <Typography variant={"subtitle1"} color={"primary.light"}>
                                        secretariat@iteag.org
                                    </Typography>
                                </a>
                            </Box>
                        </Box>
                    </Box>
                    <Box mt={2} width={"18rem"} sx={{display: "flex", flexDirection: "column"}}>
                        <Box mr={"auto"} sx={{ml: {xs: 0, md: "auto"}}}>
                            <Box>
                                <Typography variant={"h6"} textTransform={"uppercase"}>Institut</Typography>
                            </Box>
                            <Box mb={1} borderTop={"1px solid"} width={120}/>
                            <FooterLink to={"/presentation"} text={"Décourvir ITEAG"}/>
                            <FooterLink to={"/professors"} text={"Equipe professorale"}/>
                            <FooterLink to={"/contact"} text={"Nous contacter"}/>
                            <FooterLink to={"/news"} text={"Actualités"}/>
                            <FooterLink to={"/home"} text={"Accueil"}/>
                        </Box>
                    </Box>
                    <Box mt={2} width={"18rem"} sx={{display: "flex", flexDirection: "column"}}>
                        <Box sx={{ml: {xs: 0, md: "auto"}}}>
                            <Box>
                                <Typography variant={"h6"} textTransform={"uppercase"}>Formation</Typography>
                            </Box>
                            <Box mb={1} borderTop={"1px solid"} width={120}/>
                            <FooterLink to={"/education"} text={"Se former à ITEAG"}/>
                            <FooterLink to={"/diploma"} text={"Parcours diplômant / libre"}/>
                            <FooterLink to={"/educationinministry"} text={"Parcours ITEAG Pro"}/>
                            <FooterLink to={"/enroll"} text={"S'inscrire à ITEAG"}/>
                            <FooterLink to={"/news"} text={"Formation en cours"}/>
                        </Box>
                    </Box>
                </Box>
            </Container>


            <Box mt={4} mb={4}>
                <Container>
                    <Box pt={4} sx={{display: "flex", flexDirection: {xs: "column", sm: "row"}}}
                         borderTop={"1px solid"}>
                        <Box sx={{ml: {xs: "auto", sm: 0}, mr: {xs: "auto", sm: 0}}}>
                            <Typography variant={"subtitle2"} textTransform={"uppercase"}>Tous droits réservés {year},
                                ITEAG</Typography>
                        </Box>
                        <Box sx={{ml: "auto", mr: {xs: "auto", sm: 0}, mt: {xs: 2, sm: 0}}}>
                            <Box component={"a"} href={"https://fr-fr.facebook.com/iteag"} target="_blank" p={1} mr={1}
                                 sx={{color: "inherit", cursor: "pointer", "&:hover": {color: "primary.contrastText"}}}>
                                <FacebookIcon/>
                            </Box>
                            <Box component={"a"} href={"https://www.youtube.com/@formationiteag327"} target="_blank"
                                 p={1}
                                 sx={{color: "inherit", cursor: "pointer", "&:hover": {color: "primary.contrastText"}}}>
                                <YouTubeIcon/>
                            </Box>
                        </Box>
                    </Box>
                </Container>
            </Box>

        </Box>
    );
};