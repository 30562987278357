import {Box, Typography} from "@mui/material";
import React from "react";
import {InfoAvatar} from "../../Utils/InfoAvatar";

export const Testimony: React.FC<{ comment: string, name: string, position: string, img: string }> =
    ({
         comment,
         name,
         position,
         img
     }) => {
        return (
            <>
                {/*Desktop*/}
                <Box maxWidth={"lg"} ml={"auto"} mr={"auto"} height={360}
                     sx={{display: {xs: "none", md: "block"}}}>
                    <Box p={4} sx={{display: "flex", flexDirection: "column", height: "100%"}}>
                        <Typography variant={"h5"} fontStyle={"italic"} color={"#FFF"}>
                            {comment}
                        </Typography>
                        <Box ml={"auto"} mr={"auto"} mt={"auto"}>
                            <InfoAvatar name={name} position={position} img={img}/>
                        </Box>
                    </Box>
                </Box>

                {/*Mobile*/}
                <Box height={780} sx={{display: {xs: "block", md: "none"}}}>
                    <Box p={2} sx={{display: "flex", flexDirection: "column", height: "100%"}}>
                        <Typography variant={"h6"} fontStyle={"italic"} color={"#FFF"}>
                            {comment}
                        </Typography>
                        <Box ml={"auto"} mr={"auto"} mt={"auto"} mb={"auto"}>
                            <InfoAvatar name={name} position={position} img={img}/>
                        </Box>
                    </Box>
                </Box>
            </>

        );
    };