import React, {useEffect, useState} from 'react';
import {Box, createTheme, Fab, ThemeProvider} from "@mui/material";
import {HomePage} from "./layouts/Home/HomePage";
import {Navbar} from "./layouts/Navbar/Navbar";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Footer} from "./layouts/Footer/Footer";
import {frFR} from "@mui/material/locale";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {ContactPage} from "./layouts/Contact/ContactPage";
import {ScrollToTop} from "./layouts/Utils/ScrollToTop";
import {EducationPage} from "./layouts/Education/EducationPage";
import {InformationPage} from "./layouts/Information/InformationPage";
import {PresentationPage} from "./layouts/Presentation/PresentationPage";
import {ProfessorsPage} from "./layouts/Professors/ProfessorsPage";
import {NotFoundPage} from "./layouts/NotFound/NotFoundPage";
import {DiplomaPage} from "./layouts/Diploma/DiplomaPage";
import {EnrollPage} from "./layouts/Enroll/EnrollPage";
import {EducationInMinistryPage} from "./layouts/EducationInMinistry/EducationInMinistryPage";
import {
    EducationInMinistryOrientationPage
} from "./layouts/EducationInMinistryOrientation/EducationInMinistryOrientationPage";
import {ProfessorPage} from "./layouts/Professor/ProfessorPage";
import {NewsPage} from "./layouts/News/NewsPage";

export const App = () => {

    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, {passive: true});
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    const theme = createTheme({
            palette: {
                primary: {
                    main: "#355952",
                },
                secondary: {
                    main: "#EAB63E",
                },
            },
        },
        frFR,
    );

    const scrollUp = () => {
        document.body.scrollTo({
            top: 0,
            behavior: "smooth",
        });  // For Safari

        document.documentElement.scrollTo({
            top: 0,
            behavior: "smooth",
        }); // For Chrome, Firefox, IE and Opera
    };

    return (
        <ThemeProvider theme={theme}>
            <Box minHeight={"100vh"} sx={{
                display: "flex",
                flexDirection: "column",
                backgroundColor: "#FEFAEF",
            }}>
                <BrowserRouter>
                    <ScrollToTop/>
                    <Navbar/>
                    <Routes>
                        <Route path={"/"} element={<HomePage/>}/>
                        <Route path={"/contact"} element={<ContactPage/>}/>
                        <Route path={"/diploma"} element={<DiplomaPage/>}/>
                        <Route path={"/education"} element={<EducationPage/>}/>
                        <Route path={"/educationinministry"} element={<EducationInMinistryPage/>}/>
                        <Route path={"/educationinministryorientation"}
                               element={<EducationInMinistryOrientationPage/>}/>
                        <Route path={"/enroll"} element={<EnrollPage/>}/>
                        <Route path={"/home"} element={<HomePage/>}/>
                        <Route path={"/news"} element={<NewsPage/>}/>
                        <Route path={"/information"} element={<InformationPage/>}/>
                        <Route path={"/presentation"} element={<PresentationPage/>}/>
                        <Route path={"/professors"} element={<ProfessorsPage/>}/>
                        <Route path={"/professors/:name"} element={<ProfessorPage/>}/>
                        <Route path={"*"} element={<NotFoundPage/>}/>
                    </Routes>
                    <Footer/>

                    {scrollPosition > 86 &&
                      <Box>
                          {/*Desktop*/}
                        <Box sx={{display: {xs: "none", md: "block"}}} position={"fixed"} bottom={30} right={30}
                             onClick={scrollUp} zIndex={100}>
                          <Fab size={"medium"}>
                            <KeyboardArrowUpIcon/>
                          </Fab>
                        </Box>

                          {/*Mobile*/}
                        <Box sx={{display: {xs: "block", md: "none"}}} position={"fixed"} bottom={8} right={8}
                             onClick={scrollUp} zIndex={100}>
                          <Fab size={"small"}>
                            <KeyboardArrowUpIcon/>
                          </Fab>
                        </Box>
                      </Box>
                    }
                </BrowserRouter>
            </Box>
        </ThemeProvider>
    );
};