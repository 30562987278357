import {PageHeader} from "../../Utils/PageHeader";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";
import {PageParagraph} from "../../Utils/PageParagraph";
import {Box, List} from "@mui/material";
import {PageListItem} from "../../Utils/PageListItem";
import React from "react";

export const EducationInMinistryOrientation = () => {
    return (
        <Box maxWidth={"lg"}>
            <PageHeader text={`Orientation de la formation dans le ministère`}/>
            <PageSubparagraph text={`Sujets de théologie pratique : Problématiques liées aux divers aspects du ministère dans le contexte antillais.`}/>

            <PageParagraph text={`Idées de cours de la formation`}/>
            <PageSubparagraph text={`Cours pratiques`}/>
            <List>
                <PageListItem text={`Le dirigeant chrétien`}/>
                <PageListItem text={`Le travail en équipe`}/>
                <PageListItem text={`La relation d’aide`}/>
                <PageListItem text={`La prédication`}/>
                <PageListItem text={`L’étude biblique`}/>
                <PageListItem text={`Les cultes et les cérémonies`}/>
                <PageListItem text={`La médiation et la gestion des conflits`}/>
                <PageListItem text={`Accompagnement spirituel`}/>
                <PageListItem text={`Le caractère du responsable`}/>
                <PageListItem text={`Comment établir une vision et un projet d’Eglise ?`}/>
                <PageListItem text={`La gestion d’Associations`}/>
                <PageListItem text={`Les questions éthique sensibles (homosexualité, euthanasie, avortement…), comment les aborder ?`}/>
                <PageListItem text={`Les abus (d’autorité, sexuels, etc.) dans l’Eglise`}/>
                <PageListItem text={`Les addictions, comment accompagner ?`}/>
                <PageListItem text={`La discipline dans l’Eglise`}/>
                <PageListItem text={`Relation à la société et au politique, laïcité, etc.`}/>
            </List>

            <PageParagraph text={`Données pédagogiques de la formation`}/>
            <PageSubparagraph text={`Public`}/>
            <List>
                <PageListItem variant={"church"} color={"secondary.main"} text={`Formation exclusivement réservée aux pasteurs, anciens et autres responsables dans l’Eglise`}/>
            </List>
            <PageSubparagraph text={`Structure`}/>
            <List>
                <PageListItem variant={"education"} text={`Des séminaires en présentiel ou en visio`}/>
            </List>
            <PageSubparagraph text={`Cadre`}/>
            <List>
                <PageListItem variant={"union"} color={"red"} text={`Unions d’Eglises partenaires`}/>
            </List>
            <PageSubparagraph text={`Coût`}/>
            <List>
                <PageListItem variant={"euro"} color={"secondary.main"} text={`Proposition: 20€/session/participant`}/>
            </List>
            <PageSubparagraph text={`Administration`}/>
            <List>
                <PageListItem variant={"staff"} color={"red"} text={`Logistique gérée par l’ITEAG`}/>
            </List>
            <PageSubparagraph text={`Fréquence et horaires de la formation`}/>
            <List>
                <PageListItem variant={"calender"} text={`3-4 fois par an`}/>
            </List>
            <List>
                <PageListItem variant={"calender"} text={`De 9h à 12h`}/>
            </List>
            <List>
                <PageListItem variant={"calender"} text={`Le samedi`}/>
            </List>
        </Box>
    );
};