import React from "react";
import {Box} from "@mui/material";

interface AnnouncementProps {
    image: string;
}

export const News: React.FC<AnnouncementProps> = ({image}) => {
    return (
        <Box pb={1} sx={{display: "flex", flexDirection: "column", height: "100%"}}>
            <Box ml={"auto"}
                 mr={"auto"}
                 component={"img"}
                 src={image}
                 maxWidth={"100%"}
                 draggable={false}
                 sx={{height: {md: "1000px"}}}
                 />
        </Box>

    );
};