import {Box, Slide} from "@mui/material";
import {ContactForm} from "./components/ContactForm";
import {PageHeader} from "../Utils/PageHeader";
import {easing} from "../Utils/Transitions";

export const ContactPage = () => {
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
                <Box mb={4}>
                    <PageHeader text={"Nous Contacter"}/>
                </Box>
                <ContactForm/>
            </Box>
        </Slide>
    );
};