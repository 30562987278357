import {Box, Button, Slide} from "@mui/material";
import React from "react";
import {Education} from "./components/Education";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import {useNavigate} from "react-router-dom";
import {easing} from "../Utils/Transitions";
import {PageText} from "../Utils/PageText";
import {PageParagraph} from "../Utils/PageParagraph";
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import ChurchOutlinedIcon from '@mui/icons-material/ChurchOutlined';
import OndemandVideoOutlinedIcon from '@mui/icons-material/OndemandVideoOutlined';

export const EducationPage = () => {
    const navigate = useNavigate();
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
                <Education/>
                <Box mb={4} sx={{
                    borderRadius: 1,
                    boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                    p: {xs: 0, md: 2},
                    pt: 0
                }}>
                    <Box p={1} pt={0}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`Formation dans le ministère`}/>
                            <Box ml={"auto"} mr={4} fontSize={80} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <ChurchOutlinedIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageText
                            text={`Formation continue et renforcement des compétences des cadres de l’Eglise aux Antilles`}/>
                    </Box>
                    <Box display={"flex"}>
                        <Box mt={2} mb={2} sx={{ml: {xs: "auto", md: 1}, mr: {xs: "auto", md: 1}}}>
                            <Button variant={"contained"} size={"large"} endIcon={<ArrowForwardIosIcon/>}
                                    onClick={() => navigate("/educationinministry")}>
                                Parcours ITEAG Pro
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box mb={4} sx={{
                    borderRadius: 1,
                    boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                    p: {xs: 0, md: 2},
                    pt: 0
                }}>
                    <Box p={1}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`Formation en distanciel`}/>
                            <Box ml={"auto"} mr={4} fontSize={80} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <CastForEducationIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageText
                            text={`Pour les étudiants de Guyane, Martinique et autres, nous proposons une formation en distance.`}/>
                        <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, alignItems: "baseline"}}>
                            <PageText text={`Pour en savoir plus :`}/>
                            <Button size={"large"} endIcon={<ArrowForwardIosIcon/>}
                                    onClick={() => navigate("/contact")}>Nous contacter</Button>
                        </Box>
                    </Box>
                </Box>

                <Box mb={4} sx={{
                    borderRadius: 1,
                    boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                    p: {xs: 0, md: 2},
                    pt: 0
                }}>
                    <Box p={1}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`Cours Vidéo`}/>
                            <Box ml={"auto"} mr={4} fontSize={80} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <OndemandVideoOutlinedIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageText
                            text={`Nous mettons à votre disposition certains cours en format vidéo.`}/>
                        <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, alignItems: "baseline"}}>
                            <PageText text={`Vous pouvez les commander en contactant le secrétariat :`}/>
                            <Button size={"large"} endIcon={<ArrowForwardIosIcon/>}
                                    onClick={() => navigate("/contact")}>Nous contacter</Button>
                        </Box>
                    </Box>
                </Box>

                <Box mb={4} sx={{
                    borderRadius: 1,
                    boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                    p: {xs: 0, md: 2},
                    pt: 0
                }}>
                    <Box p={1}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`Bibliothèque ITEAG`}/>
                            <Box ml={"auto"} mr={4} fontSize={80} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <LocalLibraryOutlinedIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageText
                            text={`Nous possedons une belle collection d'ouvrages (> 3000) à votre disposition.`}/>
                        <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, alignItems: "baseline"}}>
                            <PageText text={`Pour en savoir plus:`}/>
                            <Button size={"large"} endIcon={<ArrowForwardIosIcon/>}
                                    onClick={() => navigate("/contact")}>Nous contacter</Button>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{
                    borderRadius: 1,
                    boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                    p: {xs: 0, md: 2},
                    pt: 0
                }}>
                    <Box p={1}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`Equipe professorale`}/>
                            <Box ml={"auto"} mr={4} fontSize={80} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <GroupsOutlinedIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageText text={`Chevronnés, souvent Antillo-Guyanais, et particulièrement 
                        disposés à partager leur savoir aux Antilles-Guyane`}/>
                    </Box>

                    <Box display={"flex"}>
                        <Box mt={2} mb={2} sx={{ml: {xs: "auto", md: 1}, mr: {xs: "auto", md: 1}}}>
                            <Button size={"large"} variant={"contained"} endIcon={<ArrowForwardIosIcon/>} onClick={() => navigate("/professors")}>
                                équipe professorale
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Slide>
    );
};