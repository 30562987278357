import {Box, Slide, Typography} from "@mui/material";
import {easing} from "../Utils/Transitions";

export const NotFoundPage = () => {
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"md"} width={"100%"} mt={8} mb={8}>
                <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, alignItems: "baseline"}}>
                    <Typography variant={"h1"} color={"error"} fontWeight={700}>
                        404
                    </Typography>
                    <Typography ml={4} variant={"h2"} color={"primary.main"} fontWeight={400}>Page non
                        trouvée</Typography>
                </Box>
            </Box>
        </Slide>
    );
};