import {Box, List} from "@mui/material";
import React from "react";
import {PageListItem} from "../../Utils/PageListItem";
import {PageParagraph} from "../../Utils/PageParagraph";
import ProfessorModel from "../../../models/ProfessorModel";

interface AcademicExpertiseProps {
    professor: ProfessorModel | undefined,
}

export const AcademicExpertise: React.FC<AcademicExpertiseProps> = ({professor}) => {
    return (
        <>
            {professor?.academicExpertise && professor?.academicExpertise.length > 0 && (
                <Box>
                    <PageParagraph text={"Expertises académiques"}/>
                    <List>
                        {professor?.academicExpertise.map((expertise, index) => (
                            <Box key={index}>
                                <PageListItem text={expertise} variant={"circle"}/>
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </>

    );
};