import {Box, Typography} from "@mui/material";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
import * as React from "react";

export const ContactForm = () => {

    return (
        <Box maxWidth={"lg"}>
            <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}}}>
                <Box mb={2} component={"iframe"} sx={{
                    border: "1px solid lightgrey",
                    borderRadius: 1,
                    width: "100%",
                    display: "flex",
                    flexGrow: 1,
                    loading: "lazy",
                }}
                     src="https://www.openstreetmap.org/export/embed.html?bbox=-61.495027542114265%2C16.29129673562369%2C-61.490548253059394%2C16.293752805381285&amp;layer=mapnik&amp;marker=16.292524774348983%2C-61.49278789758682"
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    mt: {xs: 4, md: 0},
                    ml: {xs: 0, md: 4},
                    width: {xs: "100%", md: "40%"}
                }}>
                    <Box ml={"auto"} mr={"auto"} mb={2}>
                        <Typography variant={"h4"} fontWeight={500} textTransform={"uppercase"}
                                    color={"primary.main"}>Secrétariat</Typography>
                    </Box>
                    <Box mb={2} sx={{display: "flex", flexDirection: "row"}}>
                        <Box ml={2} mr={2}>
                            <AccessTimeIcon color={"primary"}/>
                        </Box>
                        <Box>
                            <Typography variant={"subtitle1"}>lundi, mardi : 9h30-12h30/13h00-17h00</Typography>
                            <Typography variant={"subtitle1"}>mercredi : 9h00-12h00</Typography>
                            <Typography variant={"subtitle1"}>jeudi : 9h30-12h30/13h00-17h00</Typography>
                            <Typography variant={"subtitle1"}>vendredi : 9h30-12h30/13h00-16h00</Typography>
                        </Box>
                    </Box>
                    <Box mb={2} sx={{display: "flex", flexDirection: "row"}}>
                        <Box ml={2} mr={2}>
                            <LocalPhoneIcon color={"primary"}/>
                        </Box>
                        <Box>
                            <a href={"tel:+590 690 37 64 17"} style={{textDecoration: "none", color: "inherit"}}>
                                <Typography variant={"subtitle1"}>+590 690 37 64 17</Typography>
                            </a>
                        </Box>
                    </Box>
                    <Box mb={2} sx={{display: "flex", flexDirection: "row"}}>
                        <Box ml={2} mr={2}>
                            <EmailIcon color={"primary"}/>
                        </Box>
                        <Box>
                            <a href={"mailto:secretariat@iteag.org"} style={{textDecoration: "none", color: "inherit"}}>
                                <Typography variant={"subtitle1"}>secretariat@iteag.org</Typography>
                            </a>
                        </Box>
                    </Box>
                    <Box mb={2} sx={{display: "flex", flexDirection: "row"}}>
                        <Box ml={2} mr={2}>
                            <LocationOnIcon color={"primary"}/>
                        </Box>
                        <Box>
                            <Typography variant={"subtitle1"}>201 lot Pointe d'Or</Typography>
                            <Typography variant={"subtitle1"}>97139 Abymes (Les)</Typography>
                            <Typography variant={"subtitle1"}>Guadeloupe</Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};