import React from "react";
import {Box, Typography} from "@mui/material";

export const PageSubHeader: React.FC<{text: string | undefined}> = ({text}) => {
    return (
        <Box mb={2}>
            <Typography sx={{display: {xs: "none", md: "block"}}} variant={"h2"} fontWeight={400} color={"primary.main"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
            <Typography sx={{display: {xs: "block", md: "none"}}} variant={"h3"} component={"h2"} fontWeight={400} color={"primary.main"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
        </Box>
    );
};