import {PageHeader} from "../../Utils/PageHeader";
import {PageParagraph} from "../../Utils/PageParagraph";
import {Box, List} from "@mui/material";
import {PageListItem} from "../../Utils/PageListItem";
import React from "react";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";

export const EducationInMinistry = () => {
    return (
        <Box maxWidth={"lg"}>
            <PageHeader text={`ITEAG Pro`}/>
            <PageSubparagraph text={`Formation continue et renforcement des compétences des cadres de l’Eglise aux Antilles`}/>

            <PageParagraph text={`Objectifs de la formation`}/>
            <PageSubparagraph text={`Caractère :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`« Savoir être » : Renforcer la vie intérieure – Renforcer les capacités intérieures.`}/>
            </List>
            <PageSubparagraph text={`Connaissance :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`« Savoir » : Acquérir, actualiser et renforcer les connaissances et les compétences pratiques.`}/>
            </List>
            <PageSubparagraph text={`Compétence :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`« Savoir faire » : Créer et consolider une communauté de compétences au service des Eglises.`}/>
            </List>


            <PageParagraph text={`Retombées de la formation`}/>
            <PageSubparagraph text={`Sur soi :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`Progrès personnel : Piété, vie intérieure, connaissance`}/>
            </List>
            <PageSubparagraph text={`Sur la manière de vivre et de faire :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`Le professionalisme dans le ministère.`}/>
            </List>
            <PageSubparagraph text={`Sur les autres :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`La crédibilité dans l’exercice du ministère.`}/>
            </List>
            <PageSubparagraph text={`Sur la nouvelle generation de serviteurs :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`La force de l’exemple.`}/>
            </List>
            <PageSubparagraph text={`Sur les Eglises :`}/>
            <List>
                <PageListItem variant={"banner"} color={"red"} text={`L’opportunité d’une dynamique inter-ecclésiale.`}/>
            </List>
        </Box>
    );
};