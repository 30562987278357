import {Box, List} from "@mui/material";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";
import React from "react";
import {PageListItem} from "../../Utils/PageListItem";
import ProfessorModel from "../../../models/ProfessorModel";

interface OtherCommitmentsProps {
    professor: ProfessorModel | undefined,
}

export const OtherCommitments: React.FC<OtherCommitmentsProps> = ({professor}) => {
    return (
        <>
            {professor?.otherCommitments && professor?.otherCommitments.length > 0 && (
                <Box>
                    <PageSubparagraph text={"Autres engagements"}/>
                    <List>
                        {professor?.otherCommitments.map((commitement, index) => (
                            <Box key={index}>
                                <PageListItem text={commitement} variant={"circle"}/>
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </>

    );
};