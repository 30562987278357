import React from "react";
import {Box, Typography} from "@mui/material";

export const PageParagraph: React.FC<{ text: string }> = ({text}) => {
    return (
        <Box mt={4} mb={2} sx={{display: "flex", flexDirection: "row"}} alignItems={"center"}>
            <Typography sx={{display: {xs: "none", md: "block"}}} variant={"h3"} component={"h2"} fontWeight={400} color={"secondary.main"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
            <Typography sx={{display: {xs: "block", md: "none"}}} variant={"h4"} component={"h2"} fontWeight={400} color={"secondary.main"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
        </Box>
    );
};