import {Box, Slide} from "@mui/material";
import React from "react";
import {ContactForm} from "../Contact/components/ContactForm";
import {Enroll} from "./components/Enroll";
import {PageHeader} from "../Utils/PageHeader";
import {easing} from "../Utils/Transitions";

export const EnrollPage = () => {
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
                <PageHeader text={`S'inscrire à ITEAG`}/>
                <Enroll/>
                <ContactForm/>
            </Box>
        </Slide>
    );
}