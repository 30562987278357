import {Box, Typography} from "@mui/material";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import React from "react";
import StepModel from "../../../models/StepModel";
import {PageText} from "../../Utils/PageText";

export const Step: React.FC<{step: StepModel, isFirst: boolean, isLast: boolean}> = ({step, isFirst, isLast}) => {
    return (
        <Box sx={{display: "flex", flexDirection: "row"}}>
            <Box pr={2} pb={2} width={140} minWidth={140} textAlign={"right"}>
                <Typography variant={"h6"} fontWeight={500} color={"primary.main"}>
                    {step.label}
                </Typography>
            </Box>
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} color={"primary.main"} fontSize={16}>
                {isFirst && <Box border={"1px solid"} borderColor={"transparent"} width={"1px"} height={"8px"}></Box>}
                {!isFirst && <Box border={"1px solid"} borderColor={"primary.light"} width={"1px"} height={"8px"}></Box>}
                <CircleOutlinedIcon fontSize={"inherit"}/>
                {!isLast && <Box flexGrow={1} border={"1px solid"} borderColor={"primary.light"} width={"1px"}></Box>}
            </Box>
            <Box pl={2} pb={2}>
                <PageText text={step.description}/>
            </Box>
        </Box>
    );
};