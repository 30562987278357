import {Box, Typography} from "@mui/material";
import React from "react";
import ProfessorModel from "../../../models/ProfessorModel";

export const ProfessorCard: React.FC<{ professor: ProfessorModel | undefined }> = ({professor}) => {

    return (
        <Box mt={2} width={"100%"} sx={{ml: {xs: "auto", md: 0}, mr: {xs: "auto", md: 4}}}>
            <Box width={"100%"}>
                <Box
                    mb={2}
                    component={"img"}
                    src={professor?.img}
                    width={"100%"}
                />
            </Box>

            <Box width={"100%"}>
                <Box sx={{display: "flex", flexDirection: "row"}}>
                    <Typography variant={"h6"} textTransform={"capitalize"} fontWeight={500}
                                color={"primary.main"}>{`${professor?.firstName}`}</Typography>
                    <Typography variant={"h6"} textTransform={"uppercase"} fontWeight={500}
                                color={"primary.main"}>{` ${professor?.lastName}`}</Typography>
                </Box>
                <Box>
                    <Typography variant={"body1"} fontWeight={500}
                                color={"text.secondary"}>{professor?.details}</Typography>
                </Box>
            </Box>
        </Box>
    );
}