import {Box, Slide} from "@mui/material";
import {Presentation} from "./components/Presentation";
import {easing} from "../Utils/Transitions";

export const PresentationPage = () => {
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
                <Presentation/>
            </Box>
        </Slide>
    );
};