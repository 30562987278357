import {Box, Slide} from "@mui/material";
import {easing} from "../Utils/Transitions";
import {ProfessorInformation} from "./components/ProfessorInformation";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import ProfessorModel from "../../models/ProfessorModel";
import professors from "../../models/professors.json";
import {ProfessorCard} from "./components/ProfessorCard";
import {AcademicBackground} from "./components/AcademicBackground";
import {AcademicExpertise} from "./components/AcademicExpertise";
import {OtherCommitments} from "./components/OtherCommitments";
import {Publications} from "./components/Publications";
import {Articles} from "./components/Articles";
import {ProfessorCourses} from "./components/ProfessorCourses";

export const ProfessorPage = () => {
    const {name} = useParams();
    const professorName = typeof name === "undefined" ? 0 : name;
    const [professor, setProfessor] = useState<ProfessorModel>();

    useEffect(() => {
        const prof = professors.find(professor => professor.firstName + professor.lastName === professorName);
        if (prof) {
            setProfessor(prof);
        }
    }, [professorName])

    return (
        <Box sx={{display: "flex", flexDirection: {xs: "column", md: "row"}}} p={1} ml={"auto"} mr={"auto"}
             maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
            <Box display={"flex"} maxWidth={250} minWidth={250}>
                <Slide in={true} direction={"right"} timeout={500} easing={easing}>
                    <Box display={"flex"}>
                        <ProfessorCard professor={professor}/>
                    </Box>
                </Slide>
            </Box>
            <Box display={"flex"}>
                <Slide in={true} direction={"up"} timeout={500} easing={easing}>
                    <Box>
                        <ProfessorInformation professor={professor}/>
                        <AcademicBackground professor={professor}/>
                        <AcademicExpertise professor={professor}/>
                        <OtherCommitments professor={professor}/>
                        <Publications professor={professor}/>
                        <Articles professor={professor}/>
                        <ProfessorCourses professor={professor}/>
                    </Box>
                </Slide>
            </Box>
        </Box>
    );
};