import {Box, Button, Container, Fade, Slide, Typography} from "@mui/material";
import HeroImage from "../../../assets/images/background/hero-home.jpg";
import {useNavigate} from "react-router-dom";
import content from "../../../models/content.json";

export const Hero = () => {
    const navigate = useNavigate();
    return (
        <Box sx={{
            backgroundImage: (`url(${HeroImage})`),
            backgroundSize: "cover",
            maxHeight: "720px",
            width: "100%",
            display: "flex",
        }}
             overflow={"hidden"}
        >

            {/*Desktop*/}
            <Box mt={"10%"} ml={"auto"} mr={"auto"} sx={{display: {xs: "none", md: "block"}}}>
                <Container>
                    <Slide direction={"left"} in={true} timeout={500}>
                        <Box p={1} mb={4} bgcolor={"rgba(255, 255, 266, 0.7)"} width={"fit-content"} borderRadius={"4px"}>
                            <Typography variant={"h5"} color={"primary.main"} fontWeight={600}
                                        textTransform={"uppercase"}>
                                {content.home.title1.fr}
                            </Typography>
                        </Box>
                    </Slide>
                    <Fade in={true} timeout={600}>
                        <Box ml={8} pl={1} pr={1} bgcolor={"rgba(255, 255, 266, 0.4)"} borderRadius={"4px"}>
                            <Typography variant={"h1"} fontWeight={600} color={"primary.dark"}>
                                {content.home.title2.fr}
                            </Typography>
                        </Box>
                    </Fade>
                    <Box ml={8} mt={4} mb={16}>
                        <Button variant={'contained'} size={"large"} onClick={() => navigate("/education")}>En
                            savoir plus</Button>
                    </Box>
                </Container>
            </Box>

            {/*Mobile*/}
            <Box m={0} mt={"20%"} sx={{display: {xs: "flex", md: "none"}, flexDirection: "column"}}>
                <Slide direction={"left"} in={true} timeout={500}>
                    <Box mr={2} p={1} mb={4} bgcolor={"rgba(255, 255, 266, 0.7)"} borderRadius={"4px"}>
                        <Typography variant={"subtitle1"} fontWeight={600} color={"primary.main"}
                                    textTransform={"uppercase"}>
                            {content.home.title1.fr}
                        </Typography>
                    </Box>
                </Slide>
                <Fade in={true} timeout={600}>
                    <Box ml={2} p={1} bgcolor={"rgba(255, 255, 266, 0.4)"} borderRadius={"4px"}>
                        <Typography variant={"h3"} fontWeight={600} color={"primary.dark"}>
                            {content.home.title2.fr}
                        </Typography>
                    </Box>
                </Fade>
                <Box ml={"auto"} mr={"auto"} mb={4} mt={"auto"}>
                    <Button variant={'contained'} size={"large"} onClick={() => navigate("/education")}>En savoir
                        plus</Button>
                </Box>
            </Box>
        </Box>
    );
};