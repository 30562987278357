import React from "react";
import ProfessorModel from "../../../models/ProfessorModel";
import {Box, CardActionArea, CardContent, CardMedia, Paper, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";

export const Professor: React.FC<{ professor: ProfessorModel | undefined }> = ({professor}) => {

    const navigate = useNavigate();
    const maxWidth = 320;

    return (
        <CardActionArea sx={{maxWidth: maxWidth, mt: 2, mb: {xs: 2, md: 8}, ml: {xs: "auto", md: 2}, mr: {xs: "auto", md: 2}}}
                        onClick={() => navigate(`/professors/${professor?.firstName}${professor?.lastName}`)}>
            <Paper elevation={1} sx={{height: "100%", "&:hover": {boxShadow: 8}, overflow: "hidden"}}>
                <CardMedia
                    component={"img"}
                    image={professor?.img}
                    alt={professor?.firstName + " " + professor?.lastName}
                />
                <CardContent>
                    <Box sx={{display: "flex", flexDirection: "row"}}>
                        <Typography variant={"h5"} textTransform={"capitalize"} fontWeight={500}
                                    color={"primary.main"}>{`${professor?.firstName}`}</Typography>
                        <Typography variant={"h5"} textTransform={"uppercase"} fontWeight={500}
                                    color={"primary.main"}>{` ${professor?.lastName}`}</Typography>
                    </Box>
                    <Typography variant={"body1"} fontWeight={500} color={"text.secondary"}>
                        {professor?.details}
                    </Typography>
                </CardContent>
            </Paper>
        </CardActionArea>
    );
};