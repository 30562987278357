import {Box, Typography} from "@mui/material";
import React from "react";
import {Link} from "react-router-dom";

export const FooterLink: React.FC<{ to: string, text: string }> = ({to, text}) => {
    return (
        <Box width={"fit-content"}>
            <Link to={to} style={{textDecoration: "inherit"}}>
                    <Typography variant={"subtitle1"} sx={{color: "primary.light", "&:hover": {color: "primary.contrastText"}}}>{text}</Typography>
            </Link>
        </Box>
    );
};