import {Link} from "react-router-dom";
import * as React from "react";
import {Button} from "@mui/material";
import {styled} from "@mui/material/styles";

const NavbarButton = styled(Button)(({theme}) => ({
    borderRadius: 0,
    height: "100%",
    padding: theme.spacing(1),
    color: theme.palette.primary.contrastText,
    "&:hover": {
        backgroundColor: theme.palette.primary.dark,
        transition: theme.transitions.create('all', {
            duration: theme.transitions.duration.standard,
        }),
    },
}));

export const NavbarLink: React.FC<{ href: string, label: string }> = ({href, label}) => {
    return (
        <Link
            to={href}
            style={{textDecoration: "none"}}
        >
            <NavbarButton>
                {label}
            </NavbarButton>
        </Link>
    );
};