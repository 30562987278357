import {Avatar, Box, Typography} from "@mui/material";
import React from "react";

export const InfoAvatar: React.FC<{name: string, position: string, img: string}> = ({name, position, img}) => {
    return (
        <Box>
            {/*Desktop*/}
            <Box sx={{display: {xs: "none", md: "flex"}, flexDirection: "row"}}>
                <Box pr={2}>
                    <Avatar alt={name} src={img} sx={{width: 56, height: 56}}/>
                </Box>
                <Box textAlign={"left"}>
                    <Typography variant={"h6"} color={"#FFF"} textTransform={"uppercase"}>
                        {name}
                    </Typography>
                    <Typography variant={"body1"} color={"#EEE"}>
                        {position}
                    </Typography>
                </Box>
            </Box>

            {/*Mobile*/}
            <Box sx={{display: {xs: "flex", md: "none"}, flexDirection: "column"}}>
                <Box ml={"auto"} mr={"auto"}>
                    <Avatar alt={name} src={img} sx={{width: 56, height: 56}}/>
                </Box>
                <Box textAlign={"center"}>
                    <Typography variant={"h6"} color={"#FFF"} textTransform={"uppercase"}>
                        {name}
                    </Typography>
                    <Typography variant={"body1"} color={"#EEE"}>
                        {position}
                    </Typography>
                </Box>
            </Box>
        </Box>

    );
};