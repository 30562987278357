import {Box, Typography} from "@mui/material";
import Carousel from "react-material-ui-carousel";
import {Testimony} from "./Testimony";
import testimonies from "../../../models/tesimonies.json";
import BackgroundImage from "../../../assets/images/background/statement.jpg";
import React from "react";

export const Testimonies = () => {

    return (
        <Box position={"relative"}>
            <Box
                position={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                overflow={"hidden"}
                line-height={0}
            >

                {/*<svg viewBox="0 0 2400 800" opacity="1">*/}
                {/*    <g fill="#fefaefff" transform="matrix(1,0,0,1,5.82373046875,318.7289810180664)">*/}
                {/*        <path*/}
                {/*            d="M-10,10C75.41666666666666,13.75,231.25,31.541666666666668,400,28C568.75,24.458333333333332,633.3333333333333,-5.333333333333334,800,-7C966.6666666666667,-8.666666666666666,1033.3333333333333,22.916666666666668,1200,20C1366.6666666666667,17.083333333333332,1433.3333333333333,-24.125,1600,-21C1766.6666666666667,-17.875,1833.3333333333333,33.333333333333336,2000,35C2166.6666666666665,36.666666666666664,2264.5833333333335,-89.04166666666667,2400,-13C2535.4166666666665,63.04166666666667,3254.1666666666665,209.79166666666669,2650,400C2045.8333333333335,590.2083333333333,156.25,795.8333333333334,-500,900"*/}
                {/*            transform="matrix(1,0,0,1,0,25)" opacity="0.05"></path>*/}
                {/*        <path*/}
                {/*            d="M-10,10C75.41666666666666,13.75,231.25,31.541666666666668,400,28C568.75,24.458333333333332,633.3333333333333,-5.333333333333334,800,-7C966.6666666666667,-8.666666666666666,1033.3333333333333,22.916666666666668,1200,20C1366.6666666666667,17.083333333333332,1433.3333333333333,-24.125,1600,-21C1766.6666666666667,-17.875,1833.3333333333333,33.333333333333336,2000,35C2166.6666666666665,36.666666666666664,2264.5833333333335,-89.04166666666667,2400,-13C2535.4166666666665,63.04166666666667,3254.1666666666665,209.79166666666669,2650,400C2045.8333333333335,590.2083333333333,156.25,795.8333333333334,-500,900"*/}
                {/*            transform="matrix(1,0,0,1,0,50)" opacity="0.53"></path>*/}
                {/*        <path*/}
                {/*            d="M-10,10C75.41666666666666,13.75,231.25,31.541666666666668,400,28C568.75,24.458333333333332,633.3333333333333,-5.333333333333334,800,-7C966.6666666666667,-8.666666666666666,1033.3333333333333,22.916666666666668,1200,20C1366.6666666666667,17.083333333333332,1433.3333333333333,-24.125,1600,-21C1766.6666666666667,-17.875,1833.3333333333333,33.333333333333336,2000,35C2166.6666666666665,36.666666666666664,2264.5833333333335,-89.04166666666667,2400,-13C2535.4166666666665,63.04166666666667,3254.1666666666665,209.79166666666669,2650,400C2045.8333333333335,590.2083333333333,156.25,795.8333333333334,-500,900"*/}
                {/*            transform="matrix(1,0,0,1,0,75)" opacity="1.00"></path>*/}
                {/*    </g>*/}
                {/*</svg>*/}

                <svg
                    data-name="Layer 1"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 1200 120"
                >
                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        fill="#FEFAEF" transform="matrix(1,0,0,1.08,0,0)" fillOpacity="0.8">
                    </path>

                    <path
                        d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                        fill="#FEFAEF" fillOpacity="1">
                    </path>
                </svg>
            </Box>
            <Box mb={4}
                 pt={12}
                 pb={6}
                 sx={{
                     mt: {sx: 4, md: 8},
                     backgroundImage: (`url(${BackgroundImage})`),
                     backgroundSize: "cover",
                     width: "100%",
                 }}
            >
            <Box textAlign={"center"}>
                    <Box mb={2}>
                        <Typography sx={{display: {xs: "none", md: "block"}}} variant={"h1"} fontWeight={400}
                                    color={"whitesmoke"}>
                            Ils l'ont vécu
                        </Typography>
                        <Typography sx={{display: {xs: "block", md: "none"}}} variant={"h2"} component={"h1"}
                                    fontWeight={400} color={"whitesmoke"}>
                            Ils l'ont vécu
                        </Typography>
                    </Box>
                </Box>
                <Carousel animation={"slide"} interval={10000}>
                    {
                        testimonies.map((testimony, index) =>
                            <Testimony key={index} comment={testimony.comment}
                                       name={testimony.name} position={testimony.position} img={testimony.img}/>)
                    }
                </Carousel>
            </Box>
        </Box>
    );
};
