import {Box, List} from "@mui/material";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";
import {PageListItem} from "../../Utils/PageListItem";
import React from "react";
import ProfessorModel from "../../../models/ProfessorModel";

interface ArticlesProps {
    professor: ProfessorModel | undefined,
}

export const Articles: React.FC<ArticlesProps> = ({professor}) => {
    return (
        <>
            {professor?.articles && professor?.articles.length > 0 && (
                <Box>
                    <PageSubparagraph text={"Articles et contributions récentes"}/>
                    <List dense={true}>
                        {professor?.articles.map((article, index) => (
                            <Box key={index}>
                                <PageListItem text={article.book} variant={"circle"}/>
                                {article.pages.length > 0 && (
                                    <Box pl={8}>
                                        <List dense={true}>
                                            {article.pages.map((page, index) => (
                                                <Box key={index}>
                                                    <PageListItem size={"small"} text={page} variant={"dash"}/>
                                                </Box>
                                            ))}
                                        </List>
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};