import {Hero} from "./components/Hero";
import {Flash} from "./components/Flash";
import {Testimonies} from "./components/Testimonies";
import {Box, Fade, Slide} from "@mui/material";
import {NewsPage} from "../News/NewsPage";

export const HomePage = () => {

    return (
        <Box>
            <Hero/>
            <Slide direction={"up"} in={true} timeout={600}>
                <Box>
                    <Fade in={true} timeout={500}>
                        <Box>
                            <Flash/>
                        </Box>
                    </Fade>
                </Box>
            </Slide>
            <Box>
                <Testimonies/>
            </Box>
            <NewsPage/>
        </Box>
    );
};
