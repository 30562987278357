import {Box, Button, List, ListItem, ListItemIcon} from "@mui/material";
import {PageHeader} from "../../Utils/PageHeader";
import CheckIcon from '@mui/icons-material/Check';
import {PageParagraph} from "../../Utils/PageParagraph";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";
import {PageText} from "../../Utils/PageText";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import {useNavigate} from "react-router-dom";
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import TipsAndUpdatesOutlinedIcon from '@mui/icons-material/TipsAndUpdatesOutlined';

export const Presentation = () => {
    const navigate = useNavigate();

    return (
        <Box maxWidth={"lg"}>
            <PageHeader text={`Institut de Théologie Evangélique des Antilles et de la Guyane`}/>

            <Box mt={8} mb={8} sx={{
                borderRadius: 1,
                boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                p: {xs: 0, md: 2},
                pt: 0
            }}>
                <Box p={1}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`L’ITEAG, Qu’est-ce que c’est ?`}/>
                            <Box ml={"auto"} mr={4} fontSize={100} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <ContactSupportOutlinedIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageSubparagraph text={`L’objet`}/>
                        <PageText
                            text={`Un centre de formation en théologie évangélique, qui s’adresse à tous ceux qui souhaitent 
                            librement développer leurs connaissances théologiques ou obtenir un diplôme qui leur permettra 
                            de mieux exercer un ministère au sein de leur assemblée.`}/>
                        <PageSubparagraph text={"La forme"}/>
                        <PageText text={`Une association loi 1905`}/>
                        <PageText text={`Un projet « fédérateur », œuvrant à l’unité évangélique`}/>
                    </Box>
                </Box>
            </Box>

            <Box mb={4} sx={{
                borderRadius: 1,
                boxShadow: {xs: "", md: "rgba(0, 0, 0, 0.2) 0px 4px 12px"},
                p: {xs: 0, md: 2},
                pt: 0
            }}>
                <Box p={1}>
                    <Box sx={{display: "flex", flexDirection: "column"}}>
                        <Box sx={{display: "flex", flexDirection: "row"}}>
                            <PageParagraph text={`Pourquoi choisir l’ITEAG ?`}/>
                            <Box ml={"auto"} mr={4} fontSize={100} color={"primary.light"} sx={{display: {xs: "none", md: "block"}}}>
                                <TipsAndUpdatesOutlinedIcon fontSize={"inherit"} color={"inherit"}/>
                            </Box>
                        </Box>
                        <PageSubparagraph text={`Une formation de qualité pour un service efficace`}/>
                        <List>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                                <PageText text={`Une formation dispensée localement`}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                                <PageText
                                    text={`Une équipe pédagogique engagée spirituellement et compétente académiquement`}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                                <PageText text={`Une formation à la fois théorique et pratique`}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                                <PageText
                                    text={`Des personnes en formation disponibles pour les Eglises tout au long de leur cursus`}/>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <CheckIcon/>
                                </ListItemIcon>
                                <PageText text={`Une bibliothèque de bonne tenue accessible aux étudiants`}/>
                            </ListItem>
                        </List>
                    </Box>
                </Box>
                <Box p={1}>
                    <Button size={"large"} variant={"contained"} endIcon={<ArrowForwardIosIcon/>} onClick={() => navigate("/education")}>
                        Choisir une Formation
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};