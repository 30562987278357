import {Box, Button, Slide} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import {Diploma} from "./components/Diploma";
import {useNavigate} from "react-router-dom";
import {easing} from "../Utils/Transitions";

export const DiplomaPage = () => {
    const navigate = useNavigate();
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
                <Diploma/>
                <Box mt={8}>
                    <Button size={"large"} variant={"contained"} endIcon={<ArrowForwardIosIcon/>} onClick={() => navigate("/professors")}>équipe professorale</Button>
                </Box>
            </Box>
        </Slide>
    );
};