import React from "react";
import {Box, Typography} from "@mui/material";

interface PageHeaderProps {
    text: string | undefined,
}

export const PageHeader: React.FC<PageHeaderProps> = ({text}) => {
    return (
        <Box mb={2}>
            <Typography sx={{display: {xs: "none", md: "block"}}} variant={"h1"} fontWeight={400} color={"primary.main"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
            <Typography sx={{display: {xs: "block", md: "none"}}} variant={"h2"} component={"h1"} fontWeight={400} color={"primary.main"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
        </Box>
    );
};