import {Box, Button, Slide} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import React from "react";
import {useNavigate} from "react-router-dom";
import {EducationInMinistryOrientation} from "./components/EducationInMinistryOrientation";
import {easing} from "../Utils/Transitions";

export const EducationInMinistryOrientationPage = () => {
    const navigate = useNavigate();
    return (
        <Slide in={true} direction={"up"} timeout={500} easing={easing}>
            <Box p={1} ml={"auto"} mr={"auto"} maxWidth={"lg"} width={"100%"} mt={8} mb={8}>
                <EducationInMinistryOrientation/>
                <Box>
                    <Button size={"large"} variant={"contained"} endIcon={<ArrowForwardIosIcon/>}
                            onClick={() => navigate("/contact")}>S'inscrire</Button>
                </Box>
            </Box>
        </Slide>
    );
}