import {PageHeader} from "../../Utils/PageHeader";
import {PageParagraph} from "../../Utils/PageParagraph";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";
import {Accordion, AccordionDetails, AccordionSummary, Box, List, ListItem, ListItemIcon} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {PageText} from "../../Utils/PageText";
import React from "react";
import {PageListItem} from "../../Utils/PageListItem";
import {PageSubHeader} from "../../Utils/PageSubHeader";

export const Diploma = () => {
    return (
        <Box maxWidth={"lg"}>
            <PageHeader text={`Parcours ITEAG`}/>
            <PageSubHeader text={`(diplômant ou libre)`}/>

            <PageParagraph text={`Conditions d’obtention du diplôme de l’ITEAG`}/>
            <List>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Aucun diplôme d’entrée n’est exigé`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`L’obtention du diplôme de l’ITEAG nécessite un total de 180 ECTS`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Chaque cours validé, permet d’engranger un certain nombre de points, appelés « crédits »`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Un crédit représente un volume de travail de 25 à 30 heures`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Chaque cours validé permet d’obtenir 2,5 ECTS`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Travail écrit (dissertation, recension, commentaire de textes, résumés ou 
                    comparaisons de livres etc…`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Stages: 30 ECTS`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`VAE possible`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`En cas d’impossibilités, dissertation de fin d’études : 15 ECTS`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Les 30 ECTS manquants: cours in absentia ou e-learning de la FLTE`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Assiduité`}/>
                </ListItem>
            </List>

            <PageParagraph text={`Conditions d’obtention du bachelor de la FLTE`}/>
            <List>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Admission: Baccalauréat ou équivalent`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Lecture de l’anglais vivement conseillé`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Nécessite obtention de 180 ECTS (ceux proposés par l’ITEAG+ 30 de la FLTE)`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Mêmes conditions que pour le diplôme de l’ITEAG`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Tous les étudiants suivent les mêmes cours magistraux. La différence 
                    (Bachelor FLTE/diplôme de l’ITEAG) se fera au niveau des exigences de validation.`}/>
                </ListItem>
            </List>

            <PageParagraph text={`Modalités de délivrance des cours`}/>
            <PageSubparagraph text={`4 sessions d’une semaine par an pendant 6 ans`}/>
            <List>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Vacances de Carnaval (mi Février-Mars)`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Vacances de Pâques (Avril)`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Grandes vacances (début juillet)`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Vacances de la Toussaint (Novembre)`}/>
                </ListItem>
            </List>

            <PageSubparagraph text={`Un enseignement de qualité pour une formation réussie`}/>
            <List>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Authenticité du programme (choix des matières)`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Intervention de professeurs reconnus dans leur domaine en provenance d’hexagone et de la caraïbe`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Possibilité d’avoir un support par mail ou via secrétariat`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Possibilité de transfert des crédits obtenus d’une institution à l’autre`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Accès à une bibliothèque bien étoffée`}/>
                </ListItem>
            </List>

            <PageParagraph text={`Stage`}/>
            <List>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Importance des stages`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Différents types de stages proposés`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Encadrement des stages`}/>
                </ListItem>
                <ListItem >
                    <ListItemIcon>
                        <CheckIcon/>
                    </ListItemIcon>
                    <PageText text={`Validation des Acquis de l’Expérience`}/>
                </ListItem>
            </List>

            <PageParagraph text={`Programme des cours de l’ITEAG`}/>
            <PageSubparagraph text={`5 disciplines principales`}/>

            <Accordion elevation={0} sx={{bgcolor: "transparent"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                    <Box ml={4} color={"text.secondary"}>
                        <PageText text={`Ancien Testament`}/>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <PageListItem size={"small"} text={`Le canon de l’AT`}/>
                    <PageListItem size={"small"} text={`Le Pentateuque`}/>
                    <PageListItem size={"small"} text={`De Josué à 2 Rois`}/>
                    <PageListItem size={"small"} text={`Les prophètes`}/>
                    <PageListItem size={"small"} text={`La littérature sapientiale`}/>
                    <PageListItem size={"small"} text={`Les Psaumes`}/>
                    <PageListItem size={"small"} text={`La théologie de l’AT`}/>
                    <PageListItem size={"small"} text={`L’Exil et le retour`}/>
                </AccordionDetails>
            </Accordion>

            <Accordion elevation={0} sx={{bgcolor: "transparent"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                    <Box ml={4} color={"text.secondary"}>
                        <PageText text={`Nouveau Testament`}/>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <PageListItem size={"small"} text={`Jésus et son temps`}/>
                    <PageListItem size={"small"} text={`Introduction aux Evangiles`}/>
                    <PageListItem size={"small"} text={`Etude de l’évangile selon Jean`}/>
                    <PageListItem size={"small"} text={`Paul et son temps`}/>
                    <PageListItem size={"small"} text={`Etude des épîtres de Paul (aux Romains, épîtres de la captivité, pastorales)`}/>
                    <PageListItem size={"small"} text={`Les autres épîtres`}/>
                    <PageListItem size={"small"} text={`Les Actes des Apôtres`}/>
                    <PageListItem size={"small"} text={`Evangile selon Matthieu`}/>
                </AccordionDetails>
            </Accordion>

            <Accordion elevation={0} sx={{bgcolor: "transparent"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                    <Box ml={4} color={"text.secondary"}>
                        <PageText text={`Théologie systématique (ou doctrine)`}/>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <PageListItem size={"small"} text={`Doctrine de l’Écriture (bibliologie) et éléments d’herméneutique`}/>
                    <PageListItem size={"small"} text={`La doctrine du Christ (christologie)`}/>
                    <PageListItem size={"small"} text={`La doctrine du péché (hamartiologie)`}/>
                    <PageListItem size={"small"} text={`La doctrine du salut (sotériologie)`}/>
                    <PageListItem size={"small"} text={`La personne et l’oeuvre du Saint-Esprit (pneumatologie)`}/>
                    <PageListItem size={"small"} text={`La Doctrine de Dieu (théologie)`}/>
                    <PageListItem size={"small"} text={`La Création ; l’angélologie et la démonologie`}/>
                    <PageListItem size={"small"} text={`La doctrine de l’Église et des sacrements (ecclésiologie)`}/>
                    <PageListItem size={"small"} text={`La doctrine de l’homme (anthropologie)`}/>
                    <PageListItem size={"small"} text={`La doctrine des fins dernières (eschatologie)`}/>
                    <Accordion elevation={0} sx={{bgcolor: "transparent"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                            <Box ml={4} color={"text.secondary"}>
                                <PageText size={"small"} text={"Ethique"}/>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PageListItem size={"small"} text={`Les fondements de l’éthique chrétienne`}/>
                            <PageListItem size={"small"} text={`L’Ethique environnementale`}/>
                            <PageListItem size={"small"} text={`L’éthique du corps, de la vie et de la mort`}/>
                            <PageListItem size={"small"} text={`L’éthique sexuelle et familiale`}/>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion elevation={0} sx={{bgcolor: "transparent"}}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                            <Box ml={4} color={"text.secondary"}>
                                <PageText size={"small"} text={"Philosophie et spiritualité"}/>
                            </Box>
                        </AccordionSummary>
                        <AccordionDetails>
                            <PageListItem size={"small"} text={`Des mythes grecques à la philosophie christianisée`}/>
                            <PageListItem size={"small"} text={`Les grands penseurs de la Renaissance et des Lumières`}/>
                            <PageListItem size={"small"} text={`Les grands courants philosophiques contemporains`}/>
                        </AccordionDetails>
                    </Accordion>
                </AccordionDetails>
            </Accordion>

            <ListItem >
                <ListItemIcon>
                    <CheckIcon/>
                </ListItemIcon>
                <PageText text={`Histoire de l’Eglise`}/>
            </ListItem>

            <Accordion elevation={0} sx={{bgcolor: "transparent"}}>
                <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                    <Box ml={4} color={"text.secondary"}>
                        <PageText text={`Théologie pratique`}/>
                    </Box>
                </AccordionSummary>
                <AccordionDetails>
                    <PageListItem size={"small"} text={`Évangélisation`}/>
                    <PageListItem size={"small"} text={`Missiologie`}/>
                    <PageListItem size={"small"} text={`Ministère parmi les enfants et la jeunesse`}/>
                    <PageListItem size={"small"} text={`Église et engagement social`}/>
                    <PageListItem size={"small"} text={`Homilétique (prédication)`}/>
                    <PageListItem size={"small"} text={`Leadership`}/>
                    <PageListItem size={"small"} text={`Aspects juridiques des Associations ; Laïcité`}/>
                    <PageListItem size={"small"}
                        text={`Les grandes religions du monde, le vodou, les Mouvements religieux aux Antilles, le magico-religieux, etc.`}/>
                    <PageListItem size={"small"}
                        text={`Approche anthropo-théologique de la société antillaise ; lecture théologique de la littérature antillaise`}/>
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};