import {Box} from "@mui/material";
import {Step} from "./Step";
import {PageParagraph} from "../../Utils/PageParagraph";
import React from "react";
import ProfessorModel from "../../../models/ProfessorModel";

interface AcademicBackgroundProps {
    professor: ProfessorModel | undefined,
}

export const AcademicBackground: React.FC<AcademicBackgroundProps> = ({professor}) => {
    return (
        <>
            {professor?.academicBackground && professor?.academicBackground.length > 0 && (<Box>
                <PageParagraph text={"Parcours académique"}/>
                {professor?.academicBackground.map((step, index) => (
                    <Box key={index}>
                        <Step step={step} isFirst={index === 0}
                              isLast={index === professor.academicBackground.length - 1}/>
                    </Box>
                ))}
            </Box>)}
        </>

    );
};