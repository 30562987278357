import {Box} from "@mui/material";
import {PageHeader} from "../../Utils/PageHeader";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";

export const ProfessorsIntro = () => {
    return (
        <Box>
            <Box>
                <PageHeader text={"Equipe professorale"}/>
            </Box>
            <PageSubparagraph text={`Chevronnés, souvent Antillo-Guyanais, et particulièrement 
            disposés à partager leur savoir aux Antilles-Guyane`}/>
        </Box>
    );
};