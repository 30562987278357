import React from "react";
import {Box} from "@mui/material";
import ProfessorModel from "../../../models/ProfessorModel";
import {PageSubparagraph} from "../../Utils/PageSubparagraph";

interface ProfessorInformationProps {
    professor: ProfessorModel | undefined,
}

export const ProfessorInformation: React.FC<ProfessorInformationProps> = ({professor}) => {
    return (
        <>
            {professor?.information && <Box display={"flex"} flexDirection={"column"}>
                <PageSubparagraph text={professor?.information}/>
            </Box>}
        </>

    );
};