import {Accordion, AccordionDetails, AccordionSummary, Box} from "@mui/material";
import React from "react";
import {PageParagraph} from "../../Utils/PageParagraph";
import ProfessorModel from "../../../models/ProfessorModel";
import courses from "../../../models/courses.json";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {PageText} from "../../Utils/PageText";

interface ProfessorCoursesProps {
    professor: ProfessorModel | undefined,
}

export const ProfessorCourses: React.FC<ProfessorCoursesProps> = ({professor}) => {

    const professorCourses = courses.filter(course => professor?.courses.includes(course.id))

    return (
        <>
            {professor?.courses && professor?.courses.length > 0 && (
                <Box>
                    <PageParagraph text={"Cours enseigné(s) à l'ITEAG"}/>
                    {professorCourses.map(course => (
                        <Accordion key={course.id} elevation={0} sx={{bgcolor: "transparent"}}>
                            <AccordionSummary expandIcon={<ExpandMoreIcon/>} sx={{flexDirection: "row-reverse"}}>
                                <Box ml={4} color={"text.secondary"}>
                                    <PageText text={course.title}/>
                                </Box>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Box ml={8}>
                                    <PageText size={"small"} text={course.content}/>
                                </Box>
                            </AccordionDetails>
                        </Accordion>
                    ))}
                </Box>
            )}
        </>
    );
};