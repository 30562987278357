import {Box} from "@mui/material";
import {Professor} from "./Professor";
import professors from "../../../models/professors.json";
import {ProfessorsIntro} from "./ProfessorsIntro";

export const Professors = () => {

    return (
        <Box>
            <ProfessorsIntro/>
            <Box mt={4} sx={{display: "flex", flexDirection: {xs: "column", md: "row"}, flexWrap: "wrap"}}>
                {professors.map((professor, index) =>
                    <Box key={index}>
                        <Professor professor={professor}/>
                    </Box>
                )}
            </Box>
        </Box>
    );
};