import {Box, List} from "@mui/material";
import {PageListItem} from "../../Utils/PageListItem";
import React from "react";
import {PageParagraph} from "../../Utils/PageParagraph";
import ProfessorModel from "../../../models/ProfessorModel";

interface PublicationsProps {
    professor: ProfessorModel | undefined,
}

export const Publications: React.FC<PublicationsProps> = ({professor}) => {
    return (
        <>
            {professor?.publications && professor?.publications.length > 0 && (
                <Box>
                    <PageParagraph text={"Publications"}/>
                    <List dense={true}>
                        {professor?.publications.map((publication, index) => (
                            <Box key={index}>
                                <PageListItem text={publication} variant={"circle"}/>
                            </Box>
                        ))}
                    </List>
                </Box>
            )}
        </>
    );
};