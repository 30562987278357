import React from "react";
import {Box, Typography} from "@mui/material";

export const PageText: React.FC<{text: string | undefined, size?: "normal" | "small" | undefined}> = ({size, text}) => {
    let fontSize = "1.4rem";
    let fontSizeXs = "1.2rem";
    if (size === "small") {
        fontSize = "1.2rem";
        fontSizeXs = "1rem";
    }
    return (
        <Box>
            <Typography sx={{display: {xs: "none", md: "block"}}} fontSize={fontSize} color={"text.secondary"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
            <Typography sx={{display: {xs: "block", md: "none"}}} fontSize={fontSizeXs} color={"text.secondary"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
        </Box>
    );
};