import React from "react";
import {Box, ListItem, ListItemIcon} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import WhatshotIcon from '@mui/icons-material/Whatshot';
import EuroSymbolOutlinedIcon from '@mui/icons-material/EuroSymbolOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import ChurchOutlinedIcon from '@mui/icons-material/ChurchOutlined';
import CastForEducationOutlinedIcon from '@mui/icons-material/CastForEducationOutlined';
import Diversity1OutlinedIcon from '@mui/icons-material/Diversity1Outlined';
import Diversity2OutlinedIcon from '@mui/icons-material/Diversity2Outlined';
import CircleIcon from '@mui/icons-material/Circle';
import RemoveIcon from '@mui/icons-material/Remove';

import {PageText} from "./PageText";

export const PageListItem: React.FC<{text: string, variant?:
        "check" |
        "banner" |
        "euro" |
        "calender" |
        "church" |
        "education" |
        "staff" |
        "union" |
        "circle" |
        "dash" |
        undefined,
    color?:
        "primary.main" |
        "primary.light" |
        "primary.dark" |
        "secondary.main" |
        "secondary.light" |
        "secondary.dark" |
        "red" |
        undefined,
    size?:
        "normal" |
        "small" |
        undefined
}> = ({text, variant, color, size}) => {

    let icon = <CheckIcon/>;
    if (variant === "banner") icon = <WhatshotIcon color={"inherit"}/>
    if (variant === "euro") icon = <EuroSymbolOutlinedIcon color={"inherit"}/>
    if (variant === "calender") icon = <CalendarMonthOutlinedIcon color={"inherit"}/>
    if (variant === "church") icon = <ChurchOutlinedIcon color={"inherit"}/>
    if (variant === "education") icon = <CastForEducationOutlinedIcon color={"inherit"}/>
    if (variant === "staff") icon = <Diversity1OutlinedIcon color={"inherit"}/>
    if (variant === "union") icon = <Diversity2OutlinedIcon color={"inherit"}/>
    if (variant === "circle") icon = <Box fontSize={"1rem"}><CircleIcon fontSize={"inherit"} color={"inherit"}/></Box>
    if (variant === "dash") icon = <Box fontSize={"1rem"}><RemoveIcon fontSize={"inherit"} color={"inherit"}/></Box>

    return (
        <ListItem dense={size === "small"} alignItems={"flex-start"}>
            <ListItemIcon>
                <Box  sx={{color: typeof color === "undefined" ? "primary.light" : color === "red" ? "#C85250" : color}}>
                    {icon}
                </Box>
            </ListItemIcon>
            <PageText size={size} text={text}/>
        </ListItem>
    );
};