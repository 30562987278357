import React from "react";
import {Box, Typography} from "@mui/material";

export const PageSubparagraph: React.FC<{text: string | undefined}> = ({text}) => {
    return (
        <Box mt={2} mb={1}>
            <Typography sx={{display: {xs: "none", md: "block"}}} variant={"h4"} fontWeight={500} color={"text.secondary"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
            <Typography sx={{display: {xs: "block", md: "none"}}} variant={"h5"} fontWeight={500} color={"text.secondary"}>
                <span dangerouslySetInnerHTML={{ __html: typeof text === "undefined" ? "" : text}} />
            </Typography>
        </Box>
    );
};